import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../../resources/lottie-animations/assets.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "assets-explained"
    }}>{`Assets Explained`}</h1>
    <p>{`Assets are graphics which help communicate meaning or highlight areas of interaction to our users.
These may be presented individually or as part of a group with related information in components and
patterns.`}</p>
    <h2 {...{
      "id": "types-of-assets"
    }}>{`Types of Assets`}</h2>
    <h3 {...{
      "id": "system-icons"
    }}>{`System Icons`}</h3>
    <p><a parentName="p" {...{
        "href": "/styles/assets/system-icons"
      }}>{`System Icons`}</a>{` are small symbols used to convey simple actions and functions.
They are the most common icons encountered in products and help communicate metaphors at a glance.`}</p>
    <h3 {...{
      "id": "accent-icons"
    }}>{`Accent Icons`}</h3>
    <p><a parentName="p" {...{
        "href": "/styles/assets/accent-icons"
      }}>{`Accent Icons`}</a>{` are larger secondary icons used to add visual interest and
delight to by communicating the overall tone and meaning of a page.`}</p>
    <h3 {...{
      "id": "applet-icons"
    }}>{`Applet Icons`}</h3>
    <p><a parentName="p" {...{
        "href": "/styles/assets/applet-icons"
      }}>{`Applet Icons`}</a>{` convey entry points, categories of actions, or information
sources on the Workday homepage.`}</p>
    <h3 {...{
      "id": "empty-states"
    }}>{`Empty States`}</h3>
    <p><a parentName="p" {...{
        "href": "/styles/assets/empty-states"
      }}>{`Empty States`}</a>{` are a pivotal point in the user journey. They are opportunities
to build rapport, drive engagement, educate, or delight the user. Empty states should never actually
feel empty.`}</p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "creating--contributing-new-assets"
      }}>{`Creating & Contributing New Assets`}</h2>
      <p>{`Looking to create a new Icon or Illustration for your team? Check out our Getting Started guidance
for `}<a parentName="p" {...{
          "href": "/get-started/for-designers/contributing-to-canvas#tab=asset-contribution"
        }}>{`asset contribution`}</a>{`.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      